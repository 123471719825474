import { Axios } from 'utils/Axios';

export const getCompanyMe = async () => {
  try {
    const URL = `/companies/me`;
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};
